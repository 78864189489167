.container
  container()

.wrapper
  display: table
  table-layout: fixed
  width: 100%

  &_small
    border-radius: 4px
    border: 1px solid $red
    overflow: hidden

.btn
  display: block
  border: 0
  width: 100%
  margin: 0
  padding: 0
  background: $white
  height: 36px
  line-height: 36px
  border-radius: 0
  cursor: pointer
  transition: all .1s ease-out
  outline: 0
  color: $red
  font-size: 16px
  text-align: center

  &:hover
    background: $light-blue

  &_wrapper
    display: table-cell
    vertical-align: top

    &_huge
      padding: 0 column-gutter

    &_small
      border: 0
      border-left: 1px solid $red

      &:first-child
        border-left: 0

  &_huge
    border-radius: 4px
    border: 1px solid $grey500
    height 80px
    font-weight: bold
    color: $black
    font-size: 14px
    line-height: 1.2

    &.btn_active
      color: $black
      background: $blue

      &:hover
        background: $blue

  &_active
    background: $red
    color: $white

    &:hover
      background: $red

.label
  display: block
.wrapper {
  border: 1px solid #e0e0e0;
  text-align: center;
}
.wrapper:after {
  content: '';
  display: table;
  clear: both;
}
.all_link {
  display: block;
  text-align: center;
  height: 36px;
  line-height: 36px;
  margin-top: -1px;
  transition: all 0.1s ease-out;
  cursor: pointer;
  background-color: #2887e0;
  color: #fff;
  font-size: 14px;
}
.list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

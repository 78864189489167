.container {
  margin-left: -15px;
  margin-right: -15px;
}
.container:after {
  content: '';
  display: table;
  clear: both;
}
.wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.wrapper_small {
  border-radius: 4px;
  border: 1px solid #2887e0;
  overflow: hidden;
}
.btn {
  display: block;
  border: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  height: 36px;
  line-height: 36px;
  border-radius: 0;
  cursor: pointer;
  transition: all 0.1s ease-out;
  outline: 0;
  color: #2887e0;
  font-size: 16px;
  text-align: center;
}
.btn:hover {
  background: #e7eef2;
}
.btn_wrapper {
  display: table-cell;
  vertical-align: top;
}
.btn_wrapper_huge {
  padding: 0 15px;
}
.btn_wrapper_small {
  border: 0;
  border-left: 1px solid #2887e0;
}
.btn_wrapper_small:first-child {
  border-left: 0;
}
.btn_huge {
  border-radius: 4px;
  border: 1px solid #adadad;
  height: 80px;
  font-weight: bold;
  color: #333;
  font-size: 14px;
  line-height: 1.2;
}
.btn_huge.btn_active {
  color: #333;
  background: #d5edf8;
}
.btn_huge.btn_active:hover {
  background: #d5edf8;
}
.btn_active {
  background: #2887e0;
  color: #fff;
}
.btn_active:hover {
  background: #2887e0;
}
.label {
  display: block;
}

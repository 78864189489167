.item
  //height: 110px
  margin: 0 0 10px
  padding: 8px 5px 0
  text-align: center
  width: 25%

  @media screen and (min-width: 70px * 7) {
    width: 20%
  }

  @media screen and (min-width: 70px * 8) {
    spanFluid(2, 'none')
  }

.icon_wrapper
  height: 20px
  line-height: 20px
  margin-bottom: 8px

.icon
  display: inline-block
  vertical-align: middle


//.trucks
//  background-image: url($brands_trucks_image)
//
//.cars
//  background-image: url($brands_cars_image)

.name
  font-size: 12px
  color: $dark-blue

.text
  display: inline-block
  vertical-align: middle
  font-size: 15px
  line-height: 1.2
  font-weight: bold
  word-break: break-word

//for $sprite in $brands_cars_sprites {
//  $sprite_name = $sprite[9];
//  .cars-{$sprite_name} {
//    spritePosition($sprite)
//    spriteWidth($sprite)
//    spriteHeight($sprite)
//  }
//}
//
//for $sprite in $brands_trucks_sprites {
//  $sprite_name = $sprite[9];
//  .trucks-{$sprite_name} {
//    spritePosition($sprite)
//    spriteWidth($sprite)
//    spriteHeight($sprite)
//  }
//}

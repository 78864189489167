.item {
  margin: 0 0 10px;
  padding: 8px 5px 0;
  text-align: center;
  width: 25%;
}
@media screen and (min-width: 490px) {
  .item {
    width: 20%;
  }
}
@media screen and (min-width: 560px) {
  .item {
    min-height: 1px;
    float: left;
    width: 16.666666666666668%;
  }
}
.icon_wrapper {
  height: 20px;
  line-height: 20px;
  margin-bottom: 8px;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.name {
  font-size: 12px;
  color: #306bb3;
}
.text {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.2;
  font-weight: bold;
  word-break: break-word;
}

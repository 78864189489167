.wrapper
  border: 1px solid $grey200
  text-align: center
  cf()

.all_link
  display: block
  text-align: center
  height: 36px
  line-height: 36px
  margin-top: -1px
  transition: all .1s ease-out
  cursor: pointer
  background-color: $red
  color: $white
  font-size: 14px

.list
  display: flex;
  align-items flex-start
  flex-wrap wrap
